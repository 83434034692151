import { Component, OnInit, Input } from '@angular/core';
import { AppService } from '../../app.service';
import { LobListService } from './lob-list.service';
import { EntitlementService } from '../../shared/service/entitlement.service';
import { CookieService } from 'ngx-cookie-service';
import { DataCubeResponse, ILob } from '../../../models/index';
import { AuthConfig, OAuthService, JwksValidationHandler } from 'angular-oauth2-oidc';
import {
  Router, ActivatedRoute, NavigationExtras, NavigationStart, NavigationEnd, NavigationCancel, NavigationError,
  Event
} from '@angular/router';
import { PRIMARY_HOST_DOMAIN } from '../../shared/constants/app.constants';
import { NgxSpinnerService } from 'ngx-spinner';
import { loblist_resources } from '../../shared/resource/resource';
@Component({
  selector: 'app-lob-list',
  templateUrl: './lob-list.component.html',
  styleUrls: ['./lob-list.component.css']
})
export class LobListComponent implements OnInit {
  errorMessage: string;
  _isTrial = false;
  @Input()
  set isTrial(isTrial: boolean) {
    this._isTrial = isTrial;
    console.log(`LobListComponent : setting property isTrial: ${this._isTrial}`);
  }
  lobsLoaded: boolean;
  response: DataCubeResponse;
  lobs: ILob[];
  isEligible: boolean;
  appConfig: any;
  marketlandscapeUrl: string = '';

  // resources
  MarketLandscape = loblist_resources.en.MarketLandscape;
  

  constructor(private appService: AppService,
    private lobListService: LobListService,
    private entitlementService: EntitlementService,
    private cookieService: CookieService,
    private oauthService: OAuthService,
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService) {
    this.router.events.subscribe((routerEvent: Event) => {
      this.checkRouterEvent(routerEvent);
    });
  }
  ngOnInit(): void {
    this.appService.getConfigStatus().subscribe((configStatus: boolean) => {
      if (configStatus) {
        this.appConfig = this.appService.getGlobalConfig();
        const pathname = window.location.pathname;
        if(pathname.toLowerCase().startsWith('/trial')){
          this.marketlandscapeUrl = this.appConfig.marketlandscapeUrl + '/trial';
        } else{
          this.marketlandscapeUrl = this.appConfig.marketlandscapeUrl;
        }
        
        console.log(`LobListComponent : ngOnInit: getLobList is being called. isTrial is ${this._isTrial}`);
        this.getLobList();
      }
    });
  }

  checkRouterEvent(routerEvent: Event): void {
    if (routerEvent instanceof NavigationStart) {
    }

    if (routerEvent instanceof NavigationEnd) {
      if (this.cookieService.get('ticket')) {
        if (this.oauthService.getIdToken()) {
          console.log('Logged in with valid token.');
          this.entitlementService.setLoginStatus(true);
        } else if (!new RegExp('#id_token=.+').test(location.hash)) {
          this.entitlementService.setLoginStatus(false);
          this.oauthService.initImplicitFlow();
        }
      } else {
        if (this.oauthService.getIdToken()) {
          this.cookieService.deleteAll('/', PRIMARY_HOST_DOMAIN);
          this.entitlementService.setLoginStatus(false);
          this.oauthService.logoutUrl = JSON.parse(sessionStorage.getItem('id_token_claims_obj')).iss + '/connect/endsession';
          // 'https://idt.iso.com/identity/connect/endsession'
          this.oauthService.logOut();
        } else {
          this.oauthService.initImplicitFlow();
        }
      }
    }

    if (routerEvent instanceof NavigationCancel || routerEvent instanceof NavigationError) {
    }
  }

  getLobList() {
    this.spinner.show();
    this.lobListService.getLobList(this.cookieService.get('ticket'), this._isTrial).subscribe((result: DataCubeResponse) => {
      if(result) {
      
      this.response = result;
      this.lobs = result.lobs.filter(res => { return res.class != 'sub' })
      this.isEligible = result && result.isEligible;
      this.lobsLoaded = true;
      console.log(this.response);
      this.spinner.hide();
      }
    },catchError => {});
  }
  navigateToVizContainer(dashboardUrl: string, webEditUrl: string, lob: string): void {
    let link = '';
    if(this._isTrial){
      link = this.router.serializeUrl(this.router.createUrlTree(['viz-container'], { queryParams: { dashboardUrl: dashboardUrl, webEditUrl: webEditUrl, lob: lob, trial: "trial" } }));
    } 
    else {
      link = this.router.serializeUrl(this.router.createUrlTree(['viz-container'], { queryParams: { dashboardUrl: dashboardUrl, webEditUrl: webEditUrl, lob: lob } }));
    }
    window.open(`${window.location.protocol}//${window.location.host}${link}`, '_blank');
    //this.router.navigate(['viz-container'],
     // { queryParams: { dashboardUrl: dashboardUrl, webEditUrl: webEditUrl, lob: lob } });
  }
}
